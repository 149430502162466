import React from 'react';
import 'typeface-montserrat';
import styled from '../../styles/styled-components';

const FreeTagStyled = styled.div`
  background-color: ${p => p.theme.accentB600};
  border: 2px solid ${p => p.theme.accentB600};
  border-radius: 9px;
  color: #fff;
`;
const Container = styled.div`
  border: 3px dotted #fff;
  border-radius: 8px;
  padding: 4px 8px;
`;
const Title = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
`;
const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 300;
`;

const FreeTag = ({ ...props }: React.HTMLAttributes<HTMLElement>) => (
  <FreeTagStyled {...props}>
    <Container>
      <Title>Gratis</Title>
      <Subtitle>Por tiempo limitado</Subtitle>
    </Container>
  </FreeTagStyled>
);

export default FreeTag;
