import { Link } from '@reach/router';
import * as React from 'react';
import 'typeface-montserrat';
import { breakpointSmall } from '../../styles/breakpoints';
import styled from '../../styles/styled-components';
import Image from '../../types/Image';
import FreeTag from '../UI/FreeTag';
import SectionContainer from '../UI/SectionContainer';
import StartLoginButton from '../UI/StartLoginButton';

const CallToActionStyled = styled.div`
  text-align: center;
  padding: 24px 0 64px;
  background-color: ${p => p.theme.gray050};
  @media (min-width: ${breakpointSmall}px) {
    background: none;
    padding-top: 48px;
  }
`;
const ActionssContainer = styled(SectionContainer)`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;
const ActionItem = styled.div`
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
`;
const SubTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
const Description = styled.p`
  color: ${props => props.theme.gray800};
  margin-bottom: 24px;
  line-height: 1.3;
  a {
    margin-left: 4px;
  }
`;
const ItemImage = styled.img`
  margin-top: 24px;
`;
const FreeTagStyled = styled(FreeTag)`
  /* margin-top: 24px; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 3px 6px rgba(0, 0, 0, 0.3);
`;
const Next = styled.div`
  font-weight: bold;
`;
const ProductsButton = styled.a`
  font-family: Montserrat;

  text-decoration: none;
  padding: 10px;
  font-size: 15px;
  color: #ffffff;
  background-color: rgb(69, 99, 197);
  border-radius: 4px;
  border: 1px solid rgb(69, 99, 197);

  :hover {
    color: rgb(69, 99, 197);
    background-color: #ffffff;
    border: 1px solid rgb(69, 99, 197);
  }
`;

interface Props {
  whatOffer: Array<{
    heading: string;
    text: string;
    icon: Image | string;
  }>;
}

const CallToAction: React.FC<Props> = ({ whatOffer = [] }) => (
  <CallToActionStyled>
    <ActionssContainer size="wide">
      {whatOffer.map(whatoffer => (
        <ActionItem key={whatoffer.heading}>
          <ItemImage
            src={
              typeof whatoffer.icon === 'string'
                ? whatoffer.icon
                : whatoffer.icon.childImageSharp.fluid.src
            }
          />

          <SubTitle> {whatoffer.heading}</SubTitle>
          {whatoffer.heading === 'Ver mi historial de crédito' && (
            <FreeTagStyled />
          )}

          <Description>
            {' '}
            {whatoffer.text}
            {whatoffer.heading === 'Ver mi historial de crédito' && (
              <>
                <Link to="/educacion-posts/historial-de-credito/">
                  historial de crédito
                </Link>{' '}
                de manera fácil, gratuita y segura.
              </>
            )}
          </Description>
          {whatoffer.heading === 'Buscar el mejor crédito' && (
            <ProductsButton href="/productos-financieros">
              Productos financieros
            </ProductsButton>
          )}
        </ActionItem>
      ))}
    </ActionssContainer>
    <StartLoginButton />
  </CallToActionStyled>
);

export default CallToAction;
