import * as React from 'react';
import 'typeface-montserrat';
import styled from '../../styles/styled-components';
import Image from '../../types/Image';
import BackgroundSection from '../UI/BackgroundSection';
import RaiseButton from '../UI/RaiseButton';
import SectionContainer from '../UI/SectionContainer';

const AboutStyled = styled.div`
  padding: 24px 0 64px;
  position: relative;
  @media (min-width: 768px) {
    padding: 128px 0 128px;
  }
`;
const Background = styled(BackgroundSection)`
  background-image: url(${(p: BackgroundProps) => p.image});
  background-position: top center;
  background-size: cover;
  &:before {
    background: ${(props: { theme: { primary300: any } }) =>
      props.theme.primary300};
    background: linear-gradient(
      to bottom,
      ${props => props.theme.primary300} 0%,
      ${props => props.theme.primary500} 100%
    );
    opacity: 0.5;
  }
`;
const Content = styled.div`
  position: relative;
  z-index: 100;
  text-align: center;
`;
const Title = styled.h2`
  margin-top: 0;
  font-size: 60px;
  color: #fff;
`;

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;
const ContentBlock = styled.div`
  flex: 0 1 380px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 12px;
  padding: 12px 16px;
  border-radius: ${p => p.theme.borderRadius};
`;
const SubTitle = styled.h3`
  font-size: 18px;
  color: ${p => p.theme.primary500};
`;
const Text = styled.p`
  flex: 1;
  font-family: Muli;
  color: ${props => props.theme.gray800};
  line-height: 1.7;
`;
const ButtonRow = styled.div`
  margin-top: 48px;
`;
const ActionButton = styled(RaiseButton)`
  background-color: ${p => p.theme.accentB500};
`;

interface BackgroundProps {
  image: any;
}

interface Props {
  image: Image | string;
  header: string;
  imagePosition?: string;
  items: Array<{
    subtitle: string;
    text: string;
  }>;
}
const About: React.FC<Props> = ({ header, items, image }) => (
  <AboutStyled>
    <SectionContainer size="wide">
      <Background image={image} />
      <Content>
        <Title id="about">{header}</Title>

        <Blocks>
          {items.map(item => (
            <ContentBlock key={item.subtitle}>
              <SubTitle>{item.subtitle}</SubTitle>
              <Text>{item.text}</Text>
            </ContentBlock>
          ))}
        </Blocks>
        <ButtonRow>
          <ActionButton href="https://app.enbanca.co/registro">
            Regístrate
          </ActionButton>
        </ButtonRow>
      </Content>
    </SectionContainer>
  </AboutStyled>
);

export default About;
