import { graphql } from 'gatsby';
import React from 'react';
import About from '../components/Home/About';
import CallToAction from '../components/Home/CallToAction';
import Partners from '../components/Home/Partners';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero2 from '../components/UI/Hero2';
import DataMarkdown from '../types/DataMarkdown';
import Image from '../types/Image';
interface PropsTemplate {
  title: string;
  subheading: string;
  image: Image | string;
  focalPoint?: number;
  buttonText?: string;
  buttonLink?: string;
  heroComponent?: any;
  whatOffer: Array<{ heading: string; text: string; icon: Image | string }>;
  promise: {
    image: Image | string;
    header: string;
    items: Array<{ subtitle: string; text: string }>;
  };
  partners: Array<{
    link: string;
    logo: Image | string;
    name: string;
  }>;
}

export const IndexPageTemplate: React.FC<PropsTemplate> = ({
  title,
  subheading,
  image,
  focalPoint,
  buttonText,
  buttonLink,
  heroComponent,
  whatOffer,
  partners,
  promise = { image: '', header: '', items: [] },
}) => {
  const HeroComponent = heroComponent || Hero2;

  return (
    <>
      <HeroComponent
        title={title}
        subheading={subheading}
        buttonText="Ver más"
        buttonLink="#about"
        focalPoint={focalPoint}
        image={
          typeof image === 'string' ? image : image.childImageSharp.fluid.src
        }
      />
      <CallToAction whatOffer={whatOffer} />
      <About
        header={promise.header}
        items={promise.items}
        image={
          typeof promise.image === 'string'
            ? promise.image
            : promise.image.childImageSharp.fluid.src
        }
      />

      <Partners partners={partners} />
    </>
  );
};

interface Props {
  frontmatter: {
    title: string;
    subheading: string;
    image: Image;
    whatOffer: Array<{ heading: string; text: string; icon: Image | string }>;
    promise: {
      image: Image | string;
      header: string;
      items: Array<{ subtitle: string; text: string }>;
    };
    partners: Array<{
      link: string;
      logo: Image | string;
      name: string;
    }>;
  };
}

const IndexPage: React.FC<DataMarkdown<Props>> = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SEO title="Enbanca" />
      <IndexPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        image={frontmatter.image}
        whatOffer={frontmatter.whatOffer}
        promise={frontmatter.promise}
        partners={frontmatter.partners}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        whatOffer {
          icon {
            childImageSharp {
              fluid(maxWidth: 168, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          text
        }
        promise {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          header
          items {
            subtitle
            text
          }
        }
        partners {
          name
          link
          logo {
            childImageSharp {
              fluid(maxWidth: 168, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
