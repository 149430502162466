import * as React from 'react';
import { breakpointMedium, breakpointSmall } from '../../styles/breakpoints';
import styled from '../../styles/styled-components';
import BackgroundSection from './BackgroundSection';
import RaiseButton from './RaiseButton';
import SectionContainer from './SectionContainer';

interface BackgroundProps {
  image: string;
  imagePosition: number;
}

const HeroStyled = styled.div`
  position: relative;
  margin-bottom: 250px;
  @media (min-width: ${breakpointSmall}) {
    margin-bottom: initial;
    padding: 48px 0;
  }
  @media (min-width: ${breakpointMedium}) {
    padding: 64px 0;
  }
  @media (min-width: 1280px) {
    padding: 96px 0;
  }
`;
const Background = styled(BackgroundSection)`
  background-image: url(${(p: BackgroundProps) => p.image});
  background-size: cover;
  background-position: ${(p: BackgroundProps) => p.imagePosition}% center;
  height: 260px;
  &:before {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 90%, #fff 100%);
  }
  @media (min-width: ${breakpointSmall}) {
    height: 100%;
    &:before {
      background: none;
    }
  }
`;
const SectionContainerStyled = styled(SectionContainer)`
  position: relative;
  transform: translateY(198px);
  @media (min-width: ${breakpointSmall}) {
    transform: initial;
    max-width: 680px;
  }
  @media (min-width: ${breakpointMedium}) {
    max-width: 980px;
  }
`;
const Content = styled.div`
  display: flex;
  padding: 0 20px 30px;
  flex-direction: column;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
  @media (min-width: ${breakpointSmall}) {
    max-width: 300px;
    margin-left: initial;
    margin-right: initial;
    border-radius: 8px;
  }
  @media (min-width: ${breakpointMedium}) {
    max-width: 360px;
  }
`;
const Title = styled.h1`
  color: ${props => props.theme.primary500};
  font-size: 30px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${breakpointSmall}) {
    max-width: 100%;
    font-size: 24px;
  }
  @media (min-width: ${breakpointMedium}) {
    font-size: 36px;
    max-width: 100%;
  }
`;
const Text = styled.p`
  color: ${props => props.theme.gray700};
  font-size: 24px;
  font-weight: 300;
  line-height: 1.35;
  text-align: center;
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.8);
  margin-top: 0;
  @media (min-width: ${breakpointSmall}) {
    text-align: left;
  }
`;
const ButtonHero = styled(RaiseButton)`
  align-self: center;
  background-color: ${p => p.theme.primary600};
  @media (min-width: ${breakpointSmall}) {
    font-size: 16px;
    padding: 12px 24px;
  }
  @media (min-width: ${breakpointMedium}) {
    font-size: 18px;
    padding: 16px 30px;
  }
`;

interface Props {
  title: string;
  subheading?: string;
  image: string;
  imagePosition?: number;
  buttonLink?: string;
  buttonText?: string;
}

const Hero2 = ({
  title,
  subheading,
  image,
  buttonLink,
  buttonText,
  imagePosition = 50,
}: Props) => (
  <HeroStyled>
    <Background image={image} imagePosition={imagePosition} />
    <SectionContainerStyled size="wide">
      <Content>
        <Title>{title}</Title>
        {!!subheading && <Text>{subheading}</Text>}
        {!!buttonText && !!buttonLink && (
          <ButtonHero href={buttonLink}>{buttonText}</ButtonHero>
        )}
      </Content>
    </SectionContainerStyled>
  </HeroStyled>
);

export default Hero2;
