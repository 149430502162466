import styled from '../../styles/styled-components';

const BackgroundSection = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.95;
  }
`;

export default BackgroundSection;
