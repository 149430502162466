import * as React from 'react';
import styled from '../../styles/styled-components';
import Image from '../../types/Image';
import SectionContainer from '../UI/SectionContainer';

const SupportedByStyled = styled.div`
  padding: 12px 20px;
  text-align: center;
  background-color: ${props => props.theme.gray100};
`;
const SectionContainerStyled = styled(SectionContainer)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
const Logo = styled.img`
  margin: 0.5rem;
`;

interface Props {
  partners: Array<{
    link: string;
    logo: Image | string;
    name: string;
  }>;
}

const Partners: React.FC<Props> = ({ partners = [] }) => {
  return (
    <SupportedByStyled>
      <SectionContainerStyled>
        {partners.map(({ link, logo, name }) => (
          <a href={link} target="_blank" rel="noopener noreferrer" key={name}>
            <Logo
              src={
                typeof logo === 'string' ? logo : logo.childImageSharp.fluid.src
              }
            />
          </a>
        ))}
      </SectionContainerStyled>
    </SupportedByStyled>
  );
};

export default Partners;
